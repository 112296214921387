/* eslint-disable eqeqeq */
import '../styles/BlockWorld.css';
import React from 'react';
import * as THREE from "three";

import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import TileContents from '../app-components/TileContents';

class BlockWorld extends TileContents {

    constructor(props) {
        super(props);
        this.tileContentsDiv = React.createRef();
        this.scene = new THREE.Scene();
        this.FOV = 75;
        this.camera = new THREE.PerspectiveCamera(this.FOV, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.ROTATION_SPEED = 0.01;
        this.RUN_SPEED = 40;
        this.FRICTION = 6;
        this.JUMP_VELOCITY = 20;
        this.GRAVITY = 10;
        this.FLOOR_HEIGHT = 1.5;
        this.renderer = new THREE.WebGLRenderer();
        this.wireframe = false;
        this.wireframeBlock = null;
        this.matLine = null;
        this.wireframeBox = null;
        this.state = {
             showHelp: false,
             initialized: false,
             firing: false,
             selectedIndex: -1,
             hasFocus: false,
             mounted: false
        };
        this.boxGeometry = new THREE.BoxGeometry();
        this.dirtTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/img/dirt-exsmall.jpg');
        this.waterTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/img/water-exsmall.jpg');
        this.grassTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/img/grass-exsmall.jpg');
        this.treeTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/img/tree-exsmall.jpg');
        this.stoneTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/img/stone-exsmall.jpg');
        this.bfgBlasts = [];

        this.dirtTexture.magFilter = THREE.NearestFilter;
        this.waterTexture.magFilter = THREE.NearestFilter;
        this.grassTexture.magFilter = THREE.NearestFilter;
        this.treeTexture.magFilter = THREE.NearestFilter;
        this.stoneTexture.magFilter = THREE.NearestFilter;

        // const redMaterial = new THREE.MeshLambertMaterial({  side: THREE.DoubleSide, color: 0xff0000 });
        this.greenMaterial = new THREE.MeshLambertMaterial({ map: this.grassTexture, side: THREE.DoubleSide, wireframe: this.wireframe });
        this.blueMaterial = new THREE.MeshLambertMaterial({ map: this.waterTexture, side: THREE.DoubleSide, wireframe: this.wireframe });
        this.greyMaterial = new THREE.MeshLambertMaterial({ map: this.stoneTexture, side: THREE.DoubleSide, wireframe: this.wireframe });
        this.brownMaterial = new THREE.MeshLambertMaterial({ map: this.treeTexture, side: THREE.DoubleSide, wireframe: this.wireframe });
        this.lightBrownMaterial = new THREE.MeshLambertMaterial({ map: this.dirtTexture, side: THREE.DoubleSide, wireframe: this.wireframe });
        this.MAP_WIDTH = 50;
        this.MAP_HEIGHT = 10;
        this.MAP_DEPTH = 50;
        this.map = null;
        this.pointerLocked = false;
        this.mining = false;
        this.placing = false;
        this.clock = new THREE.Clock();
        this.lastTimePlaced = performance.now();
        this.miningStartTime = performance.now();
        this.firingStartTime = performance.now();
        this.blockBeingMined = null;
        this.minPlaceInterval = 200;
        this.miningInterval = 400;

        this.objects = [];
        this.inventory = [];

        this.ambientLight = new THREE.AmbientLight(0xaaaaaa);
        this.directionalLight = new THREE.DirectionalLight(0xaaaaaa, 2);
        // const controlsFPS = new FirstPersonControls(camera, renderer.domElement);
        this.controls = new PointerLockControls(this.camera, document.body);

        this.moveForward = false;
        this.moveBackward = false;
        this.moveLeft = false;
        this.moveRight = false;
        this.canJump = false;
        this.jumping = false;

        this.downRaycaster = null;
        this.moveDirectionRaycaster = null;
        this.xDirectionRaycaster = null;
        this.zDirectionRaycaster = null;
        this.blastDirectionRaycaster = null;
        this.cameraDirectionRaycaster = null;
        this.feetRaycaster = null;
        var mouse = new THREE.Vector2(); // create once

        mouse.x = 0;//( event.clientX / renderer.domElement.clientWidth ) * 2 - 1;
        mouse.y = 0;///- ( event.clientY / renderer.domElement.clientHeight ) * 2 + 1;

        this.prevTime = performance.now();
        this.velocity = new THREE.Vector3();
        this.direction = new THREE.Vector3();
        this.cameraDirection = new THREE.Vector3();
        this.bfgBlastsVelocities = [];
        this.vertex = new THREE.Vector3();
        this.moveDirectionVector = new THREE.Vector3();
        this.xDirectionVector = new THREE.Vector3();
        this.zDirectionVector = new THREE.Vector3();
        this.downVector = new THREE.Vector3();
        this.directionArrow = null;
        this.inventoryMap = {}
        this.tileContentsDiv = React.createRef();
        this.infoRef = React.createRef();
        this.infoValRef = React.createRef();
        this.positionValRef = React.createRef();
        this.blockValRef = React.createRef();
        this.aimValRef = React.createRef();
        this.inventoryRef = React.createRef();
        this.heldItem2dRef = React.createRef();
        this.pickaxeImgRef = React.createRef();
        this.startLock = this.startLock.bind(this)
        this.buildObjects = this.buildObjects.bind(this)
        this.init = this.init.bind(this)
        this.createTools = this.createTools.bind(this)
        this.createWeapons = this.createWeapons.bind(this)
        this.clearWorld = this.clearWorld.bind(this);
        this.generateWorld = this.generateWorld.bind(this);
        this.mouseWheelHandler = this.mouseWheelHandler.bind(this)
        this.mouseUpHandler = this.mouseUpHandler.bind(this)
        this.mouseDownHandler = this.mouseDownHandler.bind(this)
        this.fireWeapon = this.fireWeapon.bind(this)
        this.showMiningEffect = this.showMiningEffect.bind(this)
        this.placeBlock = this.placeBlock.bind(this)
        this.mineBlock = this.mineBlock.bind(this)
        this.removeBlock = this.removeObject.bind(this)
        this.selectInventoryItem = this.selectInventoryItem.bind(this)
        this.highlightHoveredBlock = this.highlightHoveredBlock.bind(this)
        this.getAimIntersection = this.getAimIntersection.bind(this)
        this.stopFiring = this.stopFiring.bind(this)
        this.animate = this.animate.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.createBlock = this.createBlock.bind(this)
        this.addBlockToScene = this.addBlockToScene.bind(this)
        this.generateRandomMap = this.generateRandomMap.bind(this)
        this.blockAt = this.blockAt.bind(this)
        this.generateFeature = this.generateFeature.bind(this)
        this.generateLeaves = this.generateLeaves.bind(this)
        this.onLock = this.onLock.bind(this);
        this.onUnlock = this.onUnlock.bind(this);
        this.resetPosition = this.resetPosition.bind(this);
        this.fullscreenChange = this.fullscreenChange.bind(this);
        this.updateRenderer = this.updateRenderer.bind(this)
        this.preventDefault = e => e.preventDefault()
        this.fullScreen = false;
        this.wheelTimeout = 0;
    }
    componentDidMount() {
        super.componentDidMount()
        // let rect = this.blockWorldDivRef.current.getClientRects()[0];
        // this.renderer.setSize(rect.width, rect.height);
        // this.renderer.domElement.style.width = rect.width + "px";
        // this.renderer.domElement.style.height = rect.height + "px";
        this.resizeElements.push(this.renderer.domElement);
        this.tileContentsDiv.current.appendChild(this.renderer.domElement);
        this.renderer.domElement.style.width = "100%";
        this.renderer.domElement.style.height = "100%";
        this.lastWidth = parseInt(this.renderer.domElement.clientWidth, 10);
        this.lastHeight = parseInt(this.renderer.domElement.clientHeight, 10);
        this.updateRenderer(this.lastWidth, this.lastHeight);
        this.tileContentsDiv.current.addEventListener('mousedown', this.startLock);
        this.controls.addEventListener('lock', this.onLock);
        this.controls.addEventListener('unlock', this.onUnlock);
        document.addEventListener("fullscreenchange", this.fullscreenChange);
        if (this.props.open && !this.state.mounted) {
            this.clearWorld();
            this.init();
        }
        this.setState({mounted: true});
    }
    componentWillUnmount() {
        cancelAnimationFrame(this.animate);
        this.tileContentsDiv.current.removeEventListener('mousedown', this.startLock);
        this.controls.removeEventListener('lock', this.onLock);
        this.controls.removeEventListener('unlock', this.onUnlock);
        document.removeEventListener("fullscreenchange", this.fullscreenChange);
        this.onUnlock();
        this.setState({mounted: false});
        this.clearWorld();
    }
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (this.props.open) {
            if (!prevProps.open) {
                if (!this.state.initialized) {
                    this.clearWorld()
                    this.init();
                } else { 
                    this.animate();
                }
            }
        } else {
            cancelAnimationFrame(this.animate);
            // document.body.removeChild(this.renderer.domElement);
        }

    }
    init() {
        this.buildObjects();
        this.createTools();
        this.createWeapons();
        this.resetPosition();
        this.generateWorld();
        this.animate();
        this.setState({initialized: true});
    }
    onLock() {
        this.pointerLocked = true;
        document.addEventListener('wheel', this.mouseWheelHandler, { passive: false });
        document.addEventListener('mousedown', this.mouseDownHandler);
        document.addEventListener('mouseup', this.mouseUpHandler);//, false);
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
        this.prevTime = performance.now();
        this.setState({hasFocus: true});
    }
    onUnlock() {
        this.pointerLocked = false;
        document.removeEventListener('wheel', this.mouseWheelHandler, { passive: false });
        document.removeEventListener('mousedown', this.mouseDownHandler);
        document.removeEventListener('mouseup', this.mouseUpHandler);//, false);
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keyup', this.onKeyUp);
        this.velocity.x = 0;
        this.velocity.z = 0;
        this.velocity.y = 0;
        this.moveForward = false;
        this.moveBackward = false;
        this.moveLeft = false;
        this.moveRight = false;
        this.setState({hasFocus: false});
    }
    buildObjects() {
        let inventoryMap = this.inventoryMap;
        inventoryMap["grass"] = { type: "block", blockType: "grass" };
        inventoryMap["water"] = { type: "block", blockType: "water" };
        inventoryMap["dirt"] = { type: "block", blockType: "dirt" };
        inventoryMap["tree"] = { type: "block", blockType: "tree" };
        inventoryMap["stone"] = { type: "block", blockType: "stone" };
        inventoryMap["bfg"] = { type: "weapon", weaponType: "bfg" };
        inventoryMap["pickaxe"] = { type: "tool", toolType: "pickaxe" };

    }
    clearWorld() {
        this.map = new Array(this.MAP_WIDTH);
        this.objects.length = 0;
        let scene = this.scene;
        while (scene.children.length > 0) {
            scene.remove(scene.children[0]);
        }
    }
    generateWorld() {
        let scene = this.scene;
        while (scene.children.length > 0) {
            scene.remove(scene.children[0]);
        }
        this.generateRandomMap();

        let ambientLight = this.ambientLight;
        let directionalLight = this.directionalLight;
        scene.add(ambientLight);

        directionalLight.position.set(1, 1, 0.5).normalize();
        scene.add(directionalLight);

        scene.add(this.controls.getObject());
        this.directionArrow = new THREE.ArrowHelper(this.direction, this.controls.getObject().origin, 50, 0x000000)
        this.directionArrow.setColor(0xff0000);
        scene.add(this.directionArrow);

        this.downVector.y = -1;
        this.downRaycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, -2.1, 0), 0, 2.1);
        this.moveDirectionRaycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 0, .2), 0, .2);
        this.xDirectionRaycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(.2, 0, 0), 0, .2);
        this.zDirectionRaycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 0, .2), 0, .2);
        this.blastDirectionRaycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 0, .2), 0, .2);
        this.cameraDirectionRaycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 0, .2), 0, .2);
        this.feetRaycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, -1.1, 0), 0, 1.1);

        let matLine = new LineMaterial({ color: 0x999999, linewidth: 0.003 });
        var box2Geometry = new THREE.BoxGeometry();
        var edgesGeometry = new THREE.EdgesGeometry(box2Geometry);
        var lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry(edgesGeometry);
        this.wireframeBlock = new THREE.Mesh(lineGeometry, matLine);
        scene.add(this.wireframeBlock);
    }
    createTools() {
        var img = document.createElement("img");
        img.setAttribute("src", process.env.PUBLIC_URL + "/img/pickaxe.png");
        img.style.left = "" + this.inventory.length * 52 + "px";
        img.classList.add("Blockworld-inventory-item");
        img.toolType = "pickaxe";
        this.inventoryRef.current.appendChild(img);
        this.inventory.push({ type: "tool", toolType: "pickaxe" });
        if (this.inventory.length == 1) {
            this.selectInventoryItem(0, this.state.selectedIndex);
        }
    }
    createWeapons() {
        var img = document.createElement("img");
        img.setAttribute("src", process.env.PUBLIC_URL + "/img/BFG9000icon.png");
        img.style.left = "" + this.inventory.length * 52 + "px";
        img.classList.add("Blockworld-inventory-item");
        img.weaponType = "bfg";
        this.inventoryRef.current.appendChild(img);
        this.inventory.push({ type: "weapon", weaponType: "bfg" });
        if (this.inventory.length === 1) {
            this.selectInventoryItem(0, this.state.selectedIndex);
        }
    }

    mouseWheelHandler(e) {
        e = e || window.event
        if (e.preventDefault) {
            e.preventDefault()
        }
        clearTimeout(this.wheelTimeout);
        this.wheelTimeout = setTimeout(() => {
            let lastIndex = this.state.selectedIndex;
            if (e.deltaY < 0) {
                this.state.selectedIndex -= 1;
                if (this.state.selectedIndex < 0) {
                    this.setState({selectedIndex: this.inventory.length - 1});
                }
            } else {
                this.state.selectedIndex += 1;
                if (this.state.selectedIndex == this.inventory.length) {
                    this.setState({selectedIndex: 0});
                }
            }
            this.selectInventoryItem(this.state.selectedIndex, lastIndex);
        }, 50);
    }
    mouseUpHandler(e) {
        // if (this.mining) {
        //     let img = this.pickaxeImgRef.current;
        //     img.style.marginLeft = "35%";
        // }
        this.mining = false;

        this.blockBeingMined = null;
        this.placing = false;
        this.lastTimePlaced -= this.minPlaceInterval;
    }
    startLock() {
        if (this.pointerLocked === false || !this.controls.isLocked) {
            this.controls.lock();
        }
    }
    mouseDownHandler(e) {
        // let intersect = this.getAimIntersection();
        if (e.button === 0) {
            if (this.state.selectedIndex != -1) {
                let inventoryItem = this.inventory[this.state.selectedIndex];
                if (inventoryItem.type == "tool") {
                    this.mining = true;
                } else if (inventoryItem.type == "weapon") {
                    this.fireWeapon(this.inventory[this.state.selectedIndex]);
                }
            }
        } else if (e.button === 2) {
            if (this.state.selectedIndex !== -1) {
                let inventoryItem = this.inventory[this.state.selectedIndex];
                if (inventoryItem.type == "block") {
                    this.placing = true;
                }
            }
        }

    }
    fireWeapon(weapon) {
        if (weapon.weaponType == "bfg") {
            // this.heldItem2dRef.current.childNodes.forEach((e) => e.remove());
            // var img = document.createElement("img");
            // img.setAttribute("src", process.env.PUBLIC_URL + "/img/bfgheldshooting.png");
            // img.style.display = "block";
            // this.heldItem2dRef.current.appendChild(img);
            this.firingStartTime = performance.now();
            this.setState({firing: true});
            
            let bfgBlast = new THREE.Sprite(
                new THREE.SpriteMaterial(
                    { map: new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/img/bfgblast.png') }))
            this.bfgBlasts.push(bfgBlast);
            this.scene.add(bfgBlast);
            bfgBlast.position.copy(this.controls.getObject().position)
            let bfgBlastVelocity = new THREE.Vector3();
            this.bfgBlastsVelocities.push(bfgBlastVelocity);
            this.camera.getWorldDirection(bfgBlastVelocity);
            bfgBlastVelocity.setLength(0.5);
            bfgBlast.position.add(bfgBlastVelocity);
            bfgBlast.visible = true;
        }
    }
    showMiningEffect(block, ratio) {
        let img = this.pickaxeImgRef.current;
        let angle = 45 / Math.PI * Math.sin(2 * Math.PI * ratio)
        angle %= 360;
        angle = Math.abs(angle);
        // lastMiningEffectTime = performance.now()
        img.style.transform = "rotate(" + Math.floor(angle) + "deg) translateZ(-1px)";
    }
    placeBlock(block) {
        let newBlock = this.createBlock(block.blockType);
        let closestIntersection = this.getAimIntersection()
        if (closestIntersection == null) {
            return;
        }
        let face = closestIntersection.face;
        let aimedAtBlock = closestIntersection.object;
        let normal = face.normal;
        // document.getElementById('infoVal').innerHTML = "Face indices for aim collision: " + normal.x + " " + normal.y + " " + normal.z + " ";
        newBlock.position.copy(aimedAtBlock.position);
        newBlock.position.add(normal);

        let playerPos = new THREE.Vector3();
        playerPos.copy(this.controls.getObject().position);
        if (!(Math.floor(playerPos.x) == Math.floor(newBlock.position.x) && Math.floor(playerPos.y) == Math.floor(newBlock.position.y) && Math.floor(playerPos.z) == Math.floor(newBlock.position.z))) {
            this.addBlockToScene(newBlock);
        }

    }
    removeObject(object) {
        this.scene.remove(object);
        this.objects.splice(this.objects.indexOf(object), 1);
    }
    mineBlock(block) {
        this.removeObject(block);
        let index = this.inventory.indexOf(this.inventoryMap[block.blockType]);
        if (index != -1) {
            // inventory[index]
        } else {
            var img = document.createElement("img");
            img.setAttribute("src", process.env.PUBLIC_URL + "/img/" + block.blockType + "-exsmall.jpg");
            img.style.left = "" + this.inventory.length * 52 + "px";
            img.classList.add("Blockworld-inventory-item");
            img.blockType = block.blockType;
            this.inventoryRef.current.appendChild(img);
            let inventoryItem = this.inventoryMap[block.blockType];
            this.inventory.push(inventoryItem);
            if (this.inventory.length == 1) {
                this.selectInventoryItem(0, this.state.selectedIndex);
            }
        }

    }
    selectInventoryItem(index, lastIndex) {
        // this.heldItem2dRef.current.childNodes.forEach((e) => e.remove());
        if (lastIndex != -1) {
            this.inventoryRef.current.childNodes[lastIndex].classList.remove("Blockworld-inventory-item-selected");
        }
        this.inventoryRef.current.childNodes[index].classList.add("Blockworld-inventory-item-selected");
        this.setState({selectedIndex: index});
        // var img = document.createElement("img");
        // // img.classList.add("heldItem2dImg");
        // if (this.inventory[index].type == "weapon") {
        //     if (this.inventory[index].weaponType == "bfg") {
        //         img.setAttribute("src", process.env.PUBLIC_URL + "/img/bfgheld.png");
        //         img.style.display = "block";
        //         this.heldItem2dRef.current.appendChild(img);
        //         // this.heldItem2dRef.current.childNodes;
        //     }
        // } else if (this.inventory[index].type == "tool") {
        //     img = document.createElement("img");
        //     img.setAttribute("src", process.env.PUBLIC_URL + "/img/pickaxe.png");
        //     img.style.transform = "rotate(10deg)";
        //     img.style.marginLeft = "55%";
        //     img.style.left = "25%";
        //     img.style.marginBottom = "50px";
        //     this.heldItem2dRef.current.appendChild(img);
        // }

    }
    highlightHoveredBlock(intersection) {
        if (intersection == null) {
            this.wireframeBlock.visible = false;
        } else {
            this.wireframeBlock.visible = true;
            const object = intersection.object;
            this.wireframeBlock.position.x = object.position.x;
            this.wireframeBlock.position.y = object.position.y;
            this.wireframeBlock.position.z = object.position.z;
        }
    }
    getAimIntersection() {
        this.camera.getWorldDirection(this.cameraDirection);
        this.cameraDirection.setLength(1);
        this.cameraDirectionRaycaster.set(this.controls.getObject().position, this.cameraDirection);
        this.cameraDirectionRaycaster.far = 5;
        const aimIntersections = this.cameraDirectionRaycaster.intersectObjects(this.objects);
        let intersect = null;
        if (aimIntersections.length > 0) {
            intersect = aimIntersections[0];
            // document.getElementById('instructions').innerHTML = "Block at cursor: " + intersect.object.blockType;
        }
        return intersect;
    }
    stopFiring() {
        // this.heldItem2dRef.current.childNodes.forEach((e) => e.remove());
        this.setState({firing: false});
        // if (this.state.selectedIndex != -1 &&
        //     this.inventory[this.state.selectedIndex].type == "weapon" &&
        //     this.inventory[this.state.selectedIndex].weaponType == "bfg") {
        //     var img = document.createElement("img");
        //     img.setAttribute("src", process.env.PUBLIC_URL + "/img/bfgheld.png");
        //     img.style.display = "block";
        //     this.heldItem2dRef.current.appendChild(img);
        // }
    }
    fullscreenChange(e) {
        let sceneWidth = this.lastWidth;
        let sceneHeight = this.lastHeight;
        if (this.fullScreen) {
            this.fullScreen = false;
        } else {
            this.fullScreen = true;
            sceneWidth = window.innerWidth;
            sceneHeight = window.innerHeight;
            // sceneHeight = elem.offsetHeight
        }
        this.updateRenderer(sceneWidth, sceneHeight)
    }
    updateRenderer(width, height) {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
    }
    resetPosition() {
        let camera = this.camera;
        camera.position.x = 5;
        camera.position.y = 3;
        camera.position.z = 5;
        camera.rotation.x = 0;
        camera.rotation.y = Math.PI;
        camera.rotation.z = 0;
    }
    animate() {
        if (this.props.open) {
            requestAnimationFrame(this.animate);
        }
        if (!this.fullScreen) {
            if (this.lastHeight != this.renderer.domElement.style.height || this.lastWidth != this.renderer.domElement.style.width) {
                this.lastWidth = parseInt(this.renderer.domElement.style.width, 10);
                this.lastHeight = parseInt(this.renderer.domElement.style.height, 10);
                this.updateRenderer(this.lastWidth, this.lastHeight);
            }
        }
        const time = performance.now();
        if (this.controls.isLocked === true) {
            const delta = (time - this.prevTime) / 1000;

            this.downRaycaster.set(this.controls.getObject().position, this.downVector);
            // downRaycaster.ray.origin.copy(controls.getObject().position);
            // downRaycaster.ray.origin.y -= 2.1;
            this.downRaycaster.far = 2.1;
            const underIntersections = this.downRaycaster.intersectObjects(this.objects);
            const onObject = underIntersections.length > 0;

            this.downRaycaster.far = 1.1;
            const intersectionsFeet = this.downRaycaster.intersectObjects(this.objects);
            const objectsInFeet = intersectionsFeet.length > 0;
            this.downRaycaster.far = 0.3;
            const intersectionsHead = this.downRaycaster.intersectObjects(this.objects);
            const objectsInHead = intersectionsHead.length > 0;
            if (objectsInFeet && !objectsInHead) {// && onObject) {
                this.velocity.y = 12;
            }
            this.velocity.x -= this.velocity.x * this.FRICTION * delta;
            this.velocity.z -= this.velocity.z * this.FRICTION * delta;
            this.velocity.y -= 9.8 * this.GRAVITY * delta; // 100.0 = mass
            this.direction.z = Number(this.moveForward) - Number(this.moveBackward);
            this.direction.x = Number(this.moveRight) - Number(this.moveLeft);
            this.direction.normalize();

            if (this.moveForward || this.moveBackward) {
                this.velocity.z -= this.direction.z * this.RUN_SPEED * delta;
            }
            if (this.moveLeft || this.moveRight) {
                this.velocity.x -= this.direction.x * this.RUN_SPEED * delta;
            }

            let intersect = this.getAimIntersection();
            this.highlightHoveredBlock(intersect)

            this.directionArrow.position.copy(this.controls.getObject().position);
            this.cameraDirection.setLength(1);

            this.directionArrow.position.add(this.cameraDirection);

            this.directionArrow.setDirection(this.cameraDirection);
            this.directionArrow.setLength(1, .1, .1);
            if (this.direction.length() != 0) {
                this.xDirectionVector.x = this.direction.x;
            } else {
                this.xDirectionVector.x = this.velocity.x;
            }
            this.xDirectionVector.applyQuaternion(this.camera.quaternion);
            this.xDirectionVector.setLength(1);

            this.xDirectionRaycaster.set(this.controls.getObject().position, this.xDirectionVector);
            this.xDirectionRaycaster.far = 0.4;
            const xIntersections = this.xDirectionRaycaster.intersectObjects(this.objects);
            const xBlocked = xIntersections.length > 0;
            if (xBlocked) {
                this.velocity.x = 0;
            }
            if (this.direction.length() != 0) {
                this.zDirectionVector.z = this.direction.z;
            } else {
                this.zDirectionVector.z = this.velocity.z;
            }
            this.zDirectionVector.z *= -1;
            this.zDirectionVector.applyQuaternion(this.camera.quaternion);
            this.zDirectionVector.setLength(1);

            this.zDirectionRaycaster.set(this.controls.getObject().position, this.zDirectionVector);
            this.zDirectionRaycaster.far = 0.4;
            const zIntersections = this.zDirectionRaycaster.intersectObjects(this.objects);
            const zBlocked = zIntersections.length > 0;
            if (zBlocked) {
                this.velocity.z = 0;
            }
            // function format(x) {
            //     return "" + (Math.floor(x * 100) / 100.0);
            // }
            // this.positionValRef.current.innerHTML = "" + format(this.controls.getObject().position.x) + "\t" + format(this.controls.getObject().position.y) + "\t" + format(this.controls.getObject().position.z);
            // this.aimValRef.current.innerHTML = "" + format(this.cameraDirection.x) + "\t" + format(this.cameraDirection.y) + "\t" + format(this.cameraDirection.z);

            // function stopIfBlocked() {
            //     moveDirectionVector.copy(direction);
            //     moveDirectionVector.applyQuaternion(camera.quaternion);
            //     moveDirectionVector.z *= -1;
            //     moveDirectionRaycaster.ray.set(controls.getObject().position, moveDirectionVector);
            //     moveDirectionRaycaster.far = 1;
            //     const intersectionsMoveDirection = moveDirectionRaycaster.intersectObjects(objects);
            //     const objectInWay = intersectionsMoveDirection.length > 0;

            //     if (objectInWay) {
            //         velocity.x = 0;
            //         velocity.z = 0;
            //     }
            // }
            // stopIfBlocked();
            if (onObject === true) {
                if (this.velocity.y < 0) {
                    this.controls.getObject().position.y = Math.floor(0.4 + this.controls.getObject().position.y);
                }
                this.velocity.y = Math.max(0, this.velocity.y);

                this.canJump = true;
                this.jumping = false;
            }
            this.controls.moveRight(-this.velocity.x * delta);
            this.controls.moveForward(-this.velocity.z * delta);
            this.controls.getObject().position.y += (this.velocity.y * delta); // new behavior
            if (this.controls.getObject().position.y < this.FLOOR_HEIGHT) {
                this.velocity.y = 0;
                this.controls.getObject().position.y = this.FLOOR_HEIGHT;
                this.canJump = true;
                this.jumping = false;

            }
            this.prevTime = time;

            if (this.mining) {
                if (intersect != null) {
                    let block = intersect.object;
                    if (block != this.blockBeingMined) {
                        this.miningStartTime = time;
                        this.blockBeingMined = block;
                    }
                    if (time - this.miningStartTime > this.miningInterval) {
                        // this.blockValRef.current.innerHTML = "Mined Block: " + block.blockType;
                        this.mineBlock(block);
                        this.miningStartTime = time;
                    } else {
                        this.showMiningEffect(block, (time - this.miningStartTime) / this.miningInterval);
                    }
                } else {
                    // this.blockValRef.current.innerHTML = "Mined Block: No Block";
                    this.blockBeingMined = null;
                }
            }
            if (this.placing && intersect != null) {
                if (time - this.lastTimePlaced > this.minPlaceInterval) {
                    this.placeBlock(this.inventory[this.state.selectedIndex]);
                    this.lastTimePlaced = time;
                }
            }
            let hitBfgBlastIndicies;
            for (let bfgBlastIndex in this.bfgBlasts) {
                let bfgBlast = this.bfgBlasts[bfgBlastIndex];
                if (bfgBlast.visible) {
                    let bfgBlastVelocity = this.bfgBlastsVelocities[bfgBlastIndex];
                    bfgBlast.position.add(bfgBlastVelocity);
                    this.blastDirectionRaycaster.set(bfgBlast.position, bfgBlastVelocity);
                    this.blastDirectionRaycaster.far = 0.4;
                    const blastIntersections = this.blastDirectionRaycaster.intersectObjects(this.objects);
                    const blastHit = blastIntersections.length > 0;
                    if (blastHit) {
                        bfgBlastVelocity.x = 0;
                        bfgBlastVelocity.y = 0;
                        bfgBlastVelocity.z = 0;
                        bfgBlast.visible = false;
                        if (!hitBfgBlastIndicies) {
                            hitBfgBlastIndicies = []
                        }
                        hitBfgBlastIndicies.push(bfgBlastIndex);
                        let objectHit = blastIntersections[0];
                        this.removeObject(objectHit.object);
                        this.removeObject(bfgBlast.object);
                    }
                }
            }
            if (hitBfgBlastIndicies) {
                hitBfgBlastIndicies.reverse()
                for (let bfgBlastIndex of hitBfgBlastIndicies) {
                    this.bfgBlasts.splice(bfgBlastIndex, 1);
                    this.bfgBlastsVelocities.splice(bfgBlastIndex, 1);
                }
            }
            if (this.state.firing && time - this.firingStartTime > 250) {
                this.stopFiring();
            }
            // controlsFPS.update(clock.getDelta()* 20)
        }
        this.renderer.render(this.scene, this.camera);
    }
    onKeyDown(e) {
        e = e || window.event
        if (e.preventDefault) {
            e.preventDefault()
        }

        switch (e.code) {
            case 'ArrowUp':
            case 'KeyW':
                this.moveForward = true;
                break;
            case 'ArrowLeft':
            case 'KeyA':
                this.moveLeft = true;
                break;
            case 'ArrowDown':
            case 'KeyS':
                this.moveBackward = true;
                break;
            case 'ArrowRight':
            case 'KeyD':
                this.moveRight = true;
                break;
            case 'KeyR':
                this.resetPosition()
                break;
            case 'KeyG':
                this.clearWorld();
                this.generateWorld()
                break;
            case 'KeyH':
                if (this.state.showHelp) {
                    this.setState({ showHelp: false });
                } else {
                    this.setState({ showHelp: true });
                }
                break;
            case 'KeyF':
                if (this.fullScreen) {
                    document.exitFullscreen();
                } else {
                    // let elem = this.renderer.domElement;
                    this.tileContentsDiv.current.requestFullscreen();
                }
                break;
            case 'Space':
                if (this.canJump === true && !this.jumping) {
                    this.velocity.y += this.JUMP_VELOCITY;
                    this.canJump = false;
                    this.jumping = true;
                }
                break;
            default:
                break;
        }
    }

    onKeyUp(e) {
        e = e || window.event
        if (e.preventDefault) {
            e.preventDefault()
        }
        switch (e.code) {
            case 'ArrowUp':
            case 'KeyW':
                this.moveForward = false;
                break;
            case 'ArrowLeft':
            case 'KeyA':
                this.moveLeft = false;
                break;
            case 'ArrowDown':
            case 'KeyS':
                this.moveBackward = false;
                break;
            case 'ArrowRight':
            case 'KeyD':
                this.moveRight = false;
                break;
            default:
                break;
        }
    }

    createBlock(type) {
        let block;
        if (type == 'grass') {
            block = new THREE.Mesh(this.boxGeometry, this.greenMaterial);
        }
        if (type == 'water') {
            block = new THREE.Mesh(this.boxGeometry, this.blueMaterial);
        }
        if (type == 'stone') {
            block = new THREE.Mesh(this.boxGeometry, this.greyMaterial);
        }
        if (type == 'dirt') {
            block = new THREE.Mesh(this.boxGeometry, this.lightBrownMaterial);
        }
        if (type == 'tree') {
            block = new THREE.Mesh(this.boxGeometry, this.brownMaterial);
        }
        block.blockType = type;
        return block;
    }
    addBlockToScene(block) {
        // const wireframe = new THREE.WireframeGeometry( block.geometry );
        // let line = new THREE.LineSegments( wireframe );
        // line.material.depthTest = false;
        // line.material.opacity = 0.25;
        // line.material.transparent = true;
        // line.position.x = block.position.x;
        // line.position.y = block.position.y;
        // line.position.z = block.position.z;
        // scene.add( new THREE.BoxHelper( line ) );
        this.scene.add(block);
        this.objects.push(block);
    }
    generateRandomMap() {
        let x;
        for (x = 0; x < this.MAP_WIDTH; x++) {
            this.map[x] = new Array(this.MAP_HEIGHT);
            for (var y = 0; y < this.MAP_HEIGHT; y++) {
                this.map[x][y] = new Array(this.MAP_DEPTH);
            }
        }
        var groundBlock = 0;
        for (var z = 0; z < this.MAP_DEPTH; z++) {
            for (x = 0; x < this.MAP_WIDTH; x++) {
                if (x < 5 || this.MAP_WIDTH - x < 5 ||
                    z < 5 || this.MAP_WIDTH - z < 5) {
                    groundBlock = this.createBlock("water");
                } else {
                    let ground = Math.floor(Math.random() * 3);
                    groundBlock = this.createBlock(ground == 0 ? "grass" : ground == 1 ? "dirt" : "stone");
                    if (x % (Math.floor(Math.random() * 10)) == 0 &&
                        (z % (Math.floor(Math.random() * 10)) == 0)) {
                        this.generateFeature(x, z);
                    }
                    groundBlock.position.y = 1;
                }
                groundBlock.position.x = x - 0.5;
                groundBlock.position.z = z - 0.5;
                this.addBlockToScene(groundBlock);
                this.map[x][1][z] = true;
            }
        }
    }
    blockAt(x, y, z) {
        // console.log("Checking for block at" + x + " " + y + " " + z);
        return this.map[Math.floor(x)][Math.floor(y)][Math.floor(z)];
    }
    generateFeature(x, z) {
        let height = Math.floor(Math.random() * (this.MAP_HEIGHT - 1.5));
        let floor = 1;
        var featureBlock = 0;
        for (var y = floor; y <= height + floor; y++) {
            if (height >= this.FLOOR_HEIGHT) {
                if (y == height + floor - 1) {
                    this.generateLeaves(x, y, z);
                }
                if (this.blockAt(x, y, z)) {
                    continue;
                }
                featureBlock = this.createBlock((y < height + floor - 1) ? "tree" : "grass");
            } else if (height == 1) {
                featureBlock = this.createBlock("stone");
            } else {
                return;
            }
            featureBlock.position.x = x - 0.5;
            featureBlock.position.y = y;
            featureBlock.position.z = z - 0.5;

            this.addBlockToScene(featureBlock);
            this.map[x][y][z] = true;
        }
    }

    generateLeaves(x, y, z) {
        var leafBlock = 0;
        for (var lz = -1; lz <= 1; lz++) {
            for (var lx = -1; lx <= 1; lx++) {
                if (x != 0 || z != 0) {
                    leafBlock = this.createBlock("grass");
                    leafBlock.position.x = x + lx - 0.5;
                    leafBlock.position.y = y;
                    leafBlock.position.z = z - lz - 0.5;
                    this.map[x + lx][y][z - lz] = true;
                    this.addBlockToScene(leafBlock);
                }
            }
        }
    }
    render() {
        return (<div className="Blockworld-div" ref={this.tileContentsDiv}>
            <span className="Blockworld-span" id="instructions" ref={this.instructionsRef}>{this.state.mounted && ((!this.state.hasFocus && "Click to take focus") || "Escape to exit focus")}</span>
            <span className="Blockworld-span" id="help" ref={this.instructionsRef}><br />H for help</span>
            {this.state.showHelp && <span className="Blockworld-span" id="info" ref={this.infoRef}>
                <br /><br />Move with WASD and space<br />
                R to reset position<br />
                G to generate world<br />
                Left-Click to use weapon<br />
                Hold Left mouse button to use pickaxe<br />
                Right-Click to place selected block<br />
                Mousewheel to select item<br />
                F for fullscreen
            </span>}
            <span className="Blockworld-span" id="infoVal" ref={this.infoValRef}></span>
            <span className="Blockworld-span" id="positionVal" ref={this.positionValRef}></span>
            <span className="Blockworld-span" id="blockVal" ref={this.blockValRef}></span>
            <span className="Blockworld-span" id="aimVal" ref={this.aimValRef}></span>
            <div id="inventory" ref={this.inventoryRef}></div>
            <div id="heldItem2d" ref={this.heldItem2dRef}>
                {this.state.initialized && this.inventory[this.state.selectedIndex].type == "weapon" && ((this.state.firing  && <img className="heldItem2dImg" src={process.env.PUBLIC_URL + "/img/bfgheldshooting.png"} alt=""/>) || <img className="heldItem2dImg" src={process.env.PUBLIC_URL + "/img/bfgheld.png"} alt=""/>)}
                {this.state.initialized && this.inventory[this.state.selectedIndex].type == "tool" && <img className="heldItem2dImg pickaxe" ref={this.pickaxeImgRef} src={process.env.PUBLIC_URL + "/img/pickaxe.png"} alt=""/>}
            </div>
        </div>);
    }
}
export default BlockWorld;