import '../styles/ReadWiki.css';
import React from 'react';
import TileContents from '../app-components/TileContents';

class ReadWiki extends TileContents {
    
    constructor(props) {
        super(props);
        this.state = {
            articleText: "",
            voices: window.speechSynthesis.getVoices(),
            playing: false,
            continuous: true,
            skipping: false,
            msg: null
        };
        this.tileContentsDiv = React.createRef();
        this.allOutputsNode = React.createRef();
        this.playPauseButtonPressed = this.playPauseButtonPressed.bind(this);
        this.skipPressed = this.skipPressed.bind(this);
        this.continuousButtonPressed = this.continuousButtonPressed.bind(this);
        this.readWikiLoad = this.readWikiLoad.bind(this);
        this.speak = this.speak.bind(this);
        this.speechEnded = this.speechEnded.bind(this);
        this.setPlaying = this.setPlaying.bind(this);
        this.readWikiLoad = this.readWikiLoad.bind(this);

        window.onbeforeunload = this.stopSpeech.bind(this);
    }
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (!this.props.open) {
            this.stopSpeech();
        }
    }
    componentWillUnmount() {
        this.stopSpeech();
    }
    stopSpeech() {
        window.speechSynthesis.cancel();
    }
    speak() {
        let msg = new SpeechSynthesisUtterance();
        msg.voice = this.state.voices[1];// 10
        msg.voiceURI = 'native';
        msg.volume = 1;
        msg.rate = document.getElementById("speedSlider").value;
        msg.text = this.state.articleText;
        msg.lang = 'en-us';
        msg.onend = this.speechEnded;
        window.speechSynthesis.speak(msg);
        this.setState({msg: msg});

    }
    speechEnded(e) {
        if (this.state.playing) {
            if (this.state.continuous) {
                if (!this.state.skipping) {
                    this.readWikiLoad();
                }
            } else {
                if (!this.state.skipping) {
                    this.setPlaying(false);
                }
            }
        }
    }
    setPlaying(playing) {
        this.setState({playing: playing});
    }
    playPauseButtonPressed() {
        let playing = this.state.playing;
        this.setPlaying(!playing);

        if (!playing) {
            if (this.state.articleText === "") {
                this.stopSpeech();
                this.readWikiLoad();
            } else {
                this.speak();
            }
        } else {
            window.speechSynthesis.cancel();
        }
    }
    skipPressed() {
        this.setState({skipping: true});
        this.stopSpeech();
        this.setPlaying(true);
        this.readWikiLoad();
    }
    continuousButtonPressed(event) {
        let continuous = this.state.continuous;
        this.setState({continuous: !continuous});
    }
    readWikiLoad() {
        let readWiki = this;
        fetch("https://en.wikipedia.org/api/rest_v1/page/random/summary")
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then(response => {
                return response["extract"];
            })
            .then(text => {
                readWiki.setState({articleText: text});
                let articleNode = document.createElement("div");
                articleNode.classList.add("output");
                articleNode.innerHTML = this.state.articleText;
                this.allOutputsNode.current.prepend(articleNode);
                let div = this.tileContentsDiv.current;
                this.props.scrollbarvisible(div.scrollHeight > div.clientHeight);
                readWiki.speak();
                readWiki.setState({skipping: false});
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }
    render() {
        return (<div className="App-read-wiki" ref={this.tileContentsDiv}>
            {/* <h2>Read Random Wikipedia Article Summary</h2> */}
            <div className="App-read-wiki-buttons">
                <button id="playPauseButton"
                    className="App-button" 
                    onClick={this.playPauseButtonPressed}>{(this.state.playing && "Stop") || "Play"}</button>
                <button id="skipButton" className="App-button" onClick={this.skipPressed}>Skip</button>

                <div id="continuousDiv" onClick={this.continuousButtonPressed}>
                    <input id="continuousButton" className="App-button" type="checkbox" checked={this.state.continuous} onChange={this.continuousButtonPressed}/> <span>Continuous</span>
                </div>
                <div id="speedDiv">
                    <span>Speed:</span>
                    <input type="range"
                        min="1" max="3" defaultValue="1" step="0.05" className="slider" id="speedSlider" />
                </div>
            </div>
            <div id="allOutputs" ref={this.allOutputsNode}></div>
        </div>);
    }
}
export default ReadWiki;