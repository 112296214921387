import '../styles/ButtonLink.css';
import React, { Component } from 'react';

class ButtonLink extends Component {
    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
    }
    clickHandler() {
        window.open(this.props.url);
    }
    render() {
        return (
            <a
                className="Button-link"
                // onClick={this.clickHandler}
                href={this.props.url}
                target="new"
            >
                {this.props.text || this.props.url}
            </a>);
    }
}

export default ButtonLink;