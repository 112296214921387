import '../styles/TileContents.css';
import React, { Component } from 'react';

class TileContents extends Component {

    constructor(props) {
        super(props);
        this.tileContentsDiv = React.createRef();
    }
    componentDidMount() {
        this.resizeElements = [this.tileContentsDiv.current];
        let div = this.tileContentsDiv.current;
        if (this.props.children) {
            this.props.scrollbarvisible(div.scrollHeight > div.clientHeight);
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.open) {
                this.tileContentsDiv.current.style.display = "block";
        } else {
            this.tileContentsDiv.current.style.display = "none";
        }
    }
    render() {
        return (<div className="App-tile-contents" ref={this.tileContentsDiv}>
            {this.props.children}
        </div>);
    }
}
export default TileContents;