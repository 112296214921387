import '../styles/Tile.css';
import React, { Component } from 'react';
class Tile extends Component {
    constructor(props) {
        super(props);
        this.tileRef = React.createRef();
        this.innerRef = React.createRef();
        this.backRef = React.createRef();
        this.backDivRef = React.createRef();
        this.closeButtonRef = React.createRef();
        this.demoButton = React.createRef();
        this.aboutButton = React.createRef();
        this.codeButton = React.createRef();
        this.state = { selected: "demo", demoInitialized: false, clicked: false };
        this.clickHandler = this.clickHandler.bind(this);
        this.demoClicked = this.demoClicked.bind(this);
        this.aboutClicked = this.aboutClicked.bind(this);
        this.codeClicked = this.codeClicked.bind(this);
        this.closeHandler = this.closeHandler.bind(this);
        this.updateCloseButtonPosition = this.updateCloseButtonPosition.bind(this);
        this.tileFrontDiv = React.createRef();
        this.dragElement = this.dragElement.bind(this);
        this.dragMouseDown = this.dragMouseDown.bind(this);
        this.elementDrag = this.elementDrag.bind(this);
        this.closeDragElement = this.closeDragElement.bind(this);
        this.pos1 = 0;
        this.pos2 = 0;
        this.pos3 = 0;
        this.pos4 = 0;
    }

    componentDidMount() {
        // this.dragElement(this.backDivRef.current);
        // this.dragElement(this.innerRef.current);
        // this.originalTop = this.elmnt.offsetTop;
        // this.originalLeft = this.elmnt.offsetLeft
        this.demoButton.current.classList.add("Tile-nav-button-selected")
    }
    componentDidUpdate(prevProps) {
        const { width, height } = this.props;

        if (width !== prevProps.width) {
            this.backDivRef.current.width = width;
            this.backDivRef.current.height = height;
        }
    }

    componentWillUnmount() {

        if (this.ro) {
            this.ro.disconnect();
        }
    }
    dragElement(elmnt) {
        this.elmnt = elmnt;
        this.backDivRef.current.addEventListener("mousedown", this.dragMouseDown);
        // document.addEventListener("mousedown", this.dragMouseDown);
    }

    dragMouseDown(e) {
        e = e || window.event;
        let rect = this.elmnt.getBoundingClientRect();
        if (e.clientX < rect.right - 20 &&
            e.clientY < rect.bottom - 20) {
            e.preventDefault();
            this.pos3 = e.clientX;
            this.pos4 = e.clientY;
            document.addEventListener("mouseup", this.closeDragElement);
            document.addEventListener("mousemove", this.elementDrag);
        }
    }

    elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        this.pos1 = this.pos3 - e.clientX;
        this.pos2 = this.pos4 - e.clientY;
        this.pos3 = e.clientX;
        this.pos4 = e.clientY;
        this.elmnt.style.top = (this.elmnt.offsetTop - this.pos2) + "px";
        this.elmnt.style.left = (this.elmnt.offsetLeft - this.pos1) + "px";
    }

    closeDragElement(e) {
        e = e || window.event;
        e.preventDefault()
        document.removeEventListener("mouseup", this.closeDragElement);
        document.removeEventListener("mousemove", this.elementDrag);
    }

    closeHandler() {
        const tileNode = this.tileRef.current;
        if (this.state.clicked) {
            tileNode.classList.remove("Tile-clicked");
            this.setState({ clicked: false });
        }
    }
    updateCloseButtonPosition(scrollbarVisible) {
        if (this.props.children) {
            if (scrollbarVisible) {
                this.closeButtonRef.current.classList.add("scrollbar-shifted");
            } else {
                this.closeButtonRef.current.classList.remove("scrollbar-shifted");
            }
        }
    }
    clickHandler() {
        const tileNode = this.tileRef.current;
        if (!this.state.clicked) {
            if (this.props.url) {
                const backNode = this.backRef.current;
                fetch(this.props.url)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response;
                    })
                    .then(response => {
                        return response.text();
                    })
                    .then(text => {
                        backNode.innerHTML = text;
                    })
                    .catch(error => {
                        console.error('There has been a problem with your fetch operation:', error);
                    });
            }
            tileNode.classList.add("Tile-clicked");
            this.setState({ clicked: true, demoInitialized: true });
        }
    }
    demoClicked() {
        this.demoButton.current.classList.add("Tile-nav-button-selected")
        this.aboutButton.current.classList.remove("Tile-nav-button-selected")
        this.codeButton.current.classList.remove("Tile-nav-button-selected")
        this.setState({selected: "demo"});
    }
    aboutClicked() {
        this.demoButton.current.classList.remove("Tile-nav-button-selected")
        this.aboutButton.current.classList.add("Tile-nav-button-selected")
        this.codeButton.current.classList.remove("Tile-nav-button-selected")
        this.setState({selected: "about"});
    }
    codeClicked() {
        this.demoButton.current.classList.remove("Tile-nav-button-selected")
        this.aboutButton.current.classList.remove("Tile-nav-button-selected")
        this.codeButton.current.classList.add("Tile-nav-button-selected")
        this.setState({selected: "code"});
    }
    render() {
        let demoWithProps, aboutWithProps, codeWithProps;
        let resizeElements = [this.innerRef, this.backDivRef];
        if (this.props.demo) {
            demoWithProps = React.Children.map(this.props.demo, child =>
                React.cloneElement(child, {
                    open: this.state.clicked && this.state.selected === "demo",
                    scrollbarvisible: this.updateCloseButtonPosition,
                    ref: React.createRef()
                })
            );
            aboutWithProps = React.Children.map(this.props.about, child =>
                React.cloneElement(child, {
                    open: this.state.clicked,
                    scrollbarvisible: this.updateCloseButtonPosition,
                    ref: React.createRef()
                })
            );
            codeWithProps = React.Children.map(this.props.code, child =>
                React.cloneElement(child, {
                    open: this.state.clicked,
                    scrollbarvisible: this.updateCloseButtonPosition,
                    ref: React.createRef()
                })
            );
            for (let child of demoWithProps) {
                resizeElements.push(child.ref);
            }
        }
        return (
            

            <div className="Tile" ref={this.tileRef}>
                <div className="Tile-inner" ref={this.innerRef}>
                    <div className="Tile-front"
                        onClick={this.clickHandler}
                        ref={this.tileFrontDiv}
                    >
                        <div className="Tile-background-image"
                            style={
                                {
                                    backgroundImage: "url(\"" + this.props.image + "\")"
                                }} />
                        <div className="Tile-text-container">
                            <span className="Tile-name">
                                {this.props.name}
                            </span><br /><br />
                            <span className="Tile-subtext">
                                {this.props.subtext}
                            </span>
                        </div>
                    </div>
                    <div className="Tile-back" ref={this.backDivRef}>
                        <div className="Tile-nav-bar">
                            <button className="Tile-nav-button" ref={this.demoButton} onClick={this.demoClicked}><span>Demo</span></button>
                            <button className="Tile-nav-button" ref={this.aboutButton} onClick={this.aboutClicked}><span>About</span></button>
                            <button className="Tile-nav-button" ref={this.codeButton} onClick={this.codeClicked}><span>Code</span></button>
                            <button className="Tile-close" ref={this.closeButtonRef} onClick={this.closeHandler}>
                                <svg className="octicon octicon-x" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true"><path fillRule="evenodd" d="M3.72 3.72a.75.75 0 011.06 0L8 6.94l3.22-3.22a.75.75 0 111.06 1.06L9.06 8l3.22 3.22a.75.75 0 11-1.06 1.06L8 9.06l-3.22 3.22a.75.75 0 01-1.06-1.06L6.94 8 3.72 4.78a.75.75 0 010-1.06z"></path></svg>
                                {/* X Button SVG from the Github website */}
                            </button>
                        </div>
                        {/* <TileBackContent resizeElements={resizeElements} ref={this.backRef}> */}
                            {this.state.demoInitialized && demoWithProps}
                            {this.state.selected === "about" && aboutWithProps}
                            {this.state.selected === "code" && codeWithProps}
                        {/* </TileBackContent> */}
                    </div>
                </div>
            </div>);
    }
}
export default Tile;