import '../styles/App.css';
import React, { Component } from 'react';
import NavLink from './NavLink';
import Tile from './Tile';
import ButtonLink from './ButtonLink';
import TileContents from './TileContents';
import ReadWiki from '../software-components/ReadWiki';
import BlockWorld from '../software-components/BlockWorld';
import FruitClassifier from '../software-components/FruitClassifier';
// import BombSweeper from '../software-components/BombSweeper';
class App extends Component {
    constructor(props) {
        super(props);
        this.stickyNav = this.stickyNav.bind(this);
        this.setStickyNavOffset = this.setStickyNavOffset.bind(this);
        window.onscroll = this.stickyNav;
    }
    componentDidMount() {
        this.navigation = document.getElementById("navigation");
        this.setStickyNavOffset();
        window.addEventListener('resize', this.setStickyNavOffset);
    }
    setStickyNavOffset() {
        this.navigation.classList.remove("App-nav-sticky");
        this.sticky = this.navigation.offsetTop;
        this.stickyNav()
    }
    stickyNav() {
        if (window.pageYOffset >= this.sticky) {
            this.navigation.classList.add("App-nav-sticky");
        } else {
            this.navigation.classList.remove("App-nav-sticky");
        }
    }


    render() {
        return (
            <div className="App">
                <header className="App-header" id="home">
                    <img src="../logo.png" className="App-logo" alt="logo" />
                    <h1>
                        Danny Bierek
                    </h1>
                    <h2>
                        Computer Science Graduate<br />
                        Software Engineer
                    </h2>
                </header>
                <nav className="App-navigation" id="navigation">
                    <NavLink name="Home" section="home" />
                    <NavLink name="Software" section="software" />
                    <NavLink name="About" section="about" />
                    <NavLink name="Contact" section="contact" />
                </nav>
                <main className="App-content">
                    <div id="software" className="TileContainer">
                        <Tile
                            image="bsa.png"
                            name="Appointment Scheduling"
                            subtext="Java, JavaFX, SQLite"
                            demo={
                                <TileContents>
                                    <iframe title="Appointment Scheduling Demo"
                                        width="100%"
                                        height="78%"
                                        src="https://www.youtube.com/embed/Quodz2sE15o"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen={true}></iframe>
                                    {/* <img src={process.env.PUBLIC_URL + "/bsa.png"} alt="Bierek Scheduling App" /> */}
                                </TileContents>}
                            about={<TileContents>
                                <h2>Appointment Scheduling Application</h2>
                                An application that allows a user to schedule appointments with customers
                                and maintain customer directory information.
                                <h4>Technologies Used</h4>
                                Java, JavaFX, SQLite
                            </TileContents>}
                            code={<TileContents>
                                <h2>Appointment Scheduling Application</h2>
                                Code and executable jar available at:<br />
                                <ButtonLink url="https://github.com/dbierek/BierekSchedulingApp" />
                                <br />
                                <br />
                                Uses H2 Database from:<br />
                                <ButtonLink url="https://mvnrepository.com/artifact/com.h2database/h2/1.4.200" />
                            </TileContents>}
                        />
                        <Tile
                            image="readwiki.png"
                            name="Read Wikipedia Article Aloud"
                            subtext="JavaScript, Wikipedia API, Web Speech API, React"
                            demo={<ReadWiki />}
                            about={
                                <TileContents>
                                    <h2>Read Random Wikipedia Article Aloud</h2>
                                    A program that uses the Wikipedia API to load a random article, get the one
                                    paragraph summary, and read it aloud using the Web Speech Text-to-Speech (TTS) API.
                                    Click Play to play the current article summary or load the first one, Click Skip
                                    to skip to the next article summary, Check Continuous to automatically load the next
                                    article summary after the current one has finished, and adjust the Speed slider to
                                    change the reading speed. The new speed will be used the next time the playback begins.
                                    <h4>Technologies Used</h4>
                                    JavaScript, Wikipedia API, Web Speech API
                                </TileContents>
                            }
                            code={
                                <TileContents>
                                    <h2>Read Random Wikipedia Article Aloud</h2>
                                    Code available at:<br />
                                    <ButtonLink text="ReadWikiV1.0.js" url={process.env.PUBLIC_URL + "/ReadWikiV1.0.js"} />

                                </TileContents>
                            }
                        />
                        <Tile name="Block World" subtext="JavaScript, WebGL, ThreeJS, React" image="blockworld.png"
                            demo={<BlockWorld />}
                            about={
                                <TileContents>
                                    <h2>Block World</h2>
                                    A game demo inspired by MineCraft. Features include mining and placing blocks, and
                                    shooting a weapon (the BFG 9000 from Doom), which can destroy a block. Press H to show
                                    the controls.
                                    <h4>Technologies Used</h4>
                                    JavaScript, WebGL, ThreeJS, React
                                </TileContents>
                            }
                            code={
                                <TileContents>
                                    <h2>Block World</h2>
                                    Code available at:<br />
                                    <ButtonLink text="BlockWorldV1.0.js" url={process.env.PUBLIC_URL + "/BlockWorldV1.0.js"} />
                                </TileContents>
                            }
                        />
                        <Tile
                            name="Image Classification using a 2D Convolutional Neural Network"
                            subtext="Python, Tensorflow, Keras, Flask"
                            image="FruitClassifier.png"
                            demo={<FruitClassifier />}
                            about={
                                <TileContents>
                                    <h2>Image Classification using a 2D Convolutional Neural Network</h2>
                                    A web service demo that uses a 2D CNN model trained using Keras on 10,000 images
                                    of fruit from the Fruits 360 Dataset, split into 20 classes. The backend is
                                    a Flask web service written in Python that serves the classification and
                                    feature data. Click the Load Image button to load the first, or next image. The
                                    image will be passed to the trained model, which will be used to predict the
                                    classification.
                                    <h4>Technologies Used</h4>
                                    Python, Tensorflow, Keras, Flask
                                    <br />
                                    <br />
                                    Fruits 360 Dataset available at:<br />
                                    <ButtonLink url="https://www.kaggle.com/moltean/fruits" />
                                </TileContents>
                            }
                            code={
                                <TileContents>
                                    <h2>Image Classification using a 2D Convolutional Neural Network</h2>
                                    Code available at:<br />
                                    <ButtonLink url="https://github.com/dbierek/FruitClassifier" />
                                </TileContents>
                            }
                        />
                        <Tile
                            name="Sons of Providence"
                            subtext="Video Game Mod, Github Project"
                            image="SonsOfProvidence.png"
                            demo={
                                <TileContents>
                                    <iframe title="Sons of Providence Demo"
                                      width="100%"
                                      height="78%"
                                      src="https://www.youtube.com/embed/Yl8Oi9fGbl4?si=OYkWTUCkTpN5XKlZ"
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen={true}></iframe>
                              </TileContents>
                            }
                            about={
                                <TileContents>
                                    <h2>Sons of Providence</h2>
                                    A mod for the videogame Hitman: World of Assassination. Following the original
                                    protagonist's foil, this mod includes many new missions and attempts to tell the
                                    other half of the Hitman story.
                                    <h4>Technologies Used</h4>
                                    Jenkins, Github Projects, IntelliJ IDEA, Batch, RPKG, Simple Mod Framework,
                                    QuickEntity Editor
                                </TileContents>
                            }
                            code={
                                <TileContents>
                                    <h2>Sons of Providence</h2>
                                    Code available at:<br />
                                    <ButtonLink url="https://github.com/SonsOfProvidence/SonsOfProvidence" />
                                </TileContents>
                            }
                        />
                        {/* <Tile name="Stellar STuff" subtext="JS" /> */}
                        <Tile name="Robot Pathfinding" image="QuakeBot.png" subtext="CopelliaSim, Lua"
                            demo={<TileContents>
                                {/* <img src={process.env.PUBLIC_URL + "/QuakeBot.png"} alt="Quake Bot" /> */}
                                <iframe
                                    title="Quake Bot Demonstration"
                                    width="100%"
                                    height="78%"
                                    src="https://www.youtube.com/embed/TiaH8I4UYVw"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}></iframe>
                            </TileContents>}
                            about={
                                <TileContents>
                                    <h2>Quake Bot: Disaster Recovery Robot Simulation</h2>
                                    A CopelliaSim scene that simulates a disaster recovery scenario. In this
                                    simulation, a person has been in an earthquake and has become trapped under
                                    a cabinet. The Quake Bot has been sent in to map a path to the survivor and
                                    provide remote communication as well as food, water, and first aid supplies.
                                    The Quake Bot has been given the exact location of the survivor from their
                                    cell phone's GPS coordinates. The robot has sensors that can detect objects
                                    in three cones of vision, and uses these sensors to map a path around the
                                    environment's obstacles. It breaks the environment into small sections
                                    representing movable areas, and picks the closest known section to the
                                    survivor to move towards. If the robot is unable to reach the chosen section
                                    after a set amount of time, it will pick a different section to move towards.
                                    If the robot detects that it has collided with an obstacle it will move
                                    backwards and attempt to go around the obstacle.
                                    <h4>Technologies Used</h4>
                                    CopelliaSim, Lua
                                </TileContents>
                            }
                            code={
                                <TileContents>
                                    <h2>Quake Bot: Disaster Recovery Robot Simulation</h2>
                                    CoppeliaSim File:<br />
                                    <ButtonLink url={process.env.PUBLIC_URL + "QuakeBot.ttt"} /><br />
                                    <br />
                                    Made in CoppeliaSim Edu.<br />
                                    <br />
                                    CoppeliaSim player available at:<br />
                                    <ButtonLink url="https://www.coppeliarobotics.com/downloads" />
                                </TileContents>
                            }
                        />
                        {/* <Tile name="Bomb Sweeper" subtext="JS">
                            <BombSweeper/>
                        </Tile> */}
                        {/* <Tile
                            name="WAV songs to MIDI"
                            subtext="Python" />
                        <Tile name="Poll" subtext="JS" />
                        <Tile name="Tactical Espionage" />
                        <Tile name="Final Fantlevania" subtext="JS" />
                        <Tile name="Basketball" subtext="JS, WebGL" />
                        <Tile name="Dannybierek.com" subtext="JS, WebGL, Inception"
                            url="dannybierek.com" /> */}
                    </div>
                    <div id="about" className="App-about">
                        <h5>
                            EDUCATION
                        </h5>
                        <ul>
                            <li><b>Bachelor of Science, Computer Science</b><br />
                                <span className="App-text-small">Western Governors University<br />
                                2020</span>

                            </li><br />
                            <li><b>Associate of Science, Computer Science</b><br />
                                <span className="App-text-small">Cuesta College<br />
                                2014</span><br />
                            </li>
                        </ul>
                        <br />
                        <h5>
                            CERTIFICATIONS
                        </h5>
                        <ul><span className="App-text-small">
                            <li>CompTIA A+</li>
                            <li>CompTIA Project+</li>
                            <li>CompTIA Network+</li>
                            <li>CIW User Interface Designer</li>
                            <li>CIW Advanced HTML5 and CSS3 Specialist</li>
                            <li>Oracle Database SQL Certified Associate</li>
                        </span>
                        </ul>
                        <br />
                        <h5>
                            EXPERIENCE
                        </h5>
                        <br />
                        <h5>
                            Software Engineer - Ford Pro Intelligence<br />
                            Napa, CA 2021-Current
                        </h5>
                        <span className="App-text-small">Work on Ford Telematics as a contractor for Apex Systems.
                        </span>
                        <br />
                        <br />
                        <h5>
                            Software Developer - Boeing<br />
                            San Luis Obispo, CA 2009-2013
                        </h5>
                        <span className="App-text-small">Designed and implemented UI components and services for a geographical inventory management webapp.<br />
                            (FlexML, AS3, Java, PDFBox, Scrum, Maven, Spring, JIRA, SOAP)</span>
                        <br />
                        <br />
                        <h5>Software Developer - Amazon<br />
                        San Luis Obispo, CA 2007-2008
                        </h5>
                        <span className="App-text-small">Designed and implemented internal web pages for a web-based DVD publishing and marketplace website.<br />
                            (JSP, Java, HTML/JavaScript, PostGreSQL, Test Driven Development, JIRA)</span>
                        <p />
                    </div>
                </main>
                <footer>
                    <div id="contact">
                        <a className="App-link-button"
                            href="mailto:dannybierek@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer">dannybierek@gmail.com</a>
                    </div>
                    <div id="site-about">
                        Powered by React<br /><br />
                        <span className="copyright">Copyright © 2023 Danny Bierek.</span>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
