import '../styles/NavLink.css';
import React, { Component } from 'react';

class NavLink extends Component {
    constructor(props) {
        super(props);
        this.scrollToSection = this.scrollToSection.bind(this);
    }
    scrollToSection() {
        var sectionId = this.props.section;
        document.getElementById(sectionId).scrollIntoView();
    }
    render() {
        return (
            <button
                className="App-nav-link"
                onClick={this.scrollToSection}
            >
                {this.props.name}
            </button>);
    }
}

export default NavLink;