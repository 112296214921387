import '../styles/FruitClassifier.css';
import React, { Component } from 'react';
import TileContents from '../app-components/TileContents';

class FruitFeaturesImages extends Component {
    render() {
        var featureImages = this.props.features.map(function (feature) {
            return <img src={feature} width={100} height={100} style={{ imageRendering: "pixelated" }} key={feature} alt="Feature" />;
        });
        return <div>{featureImages}</div>;
    }
}
class FruitClassifier extends TileContents {

    constructor(props) {
        super(props);
        this.state = {
            imageURL: '',
            features: '',
            classification: ''
        };
        this.tileContentsDiv = React.createRef();
        this.nextButton = React.createRef();
        this.fruitImageDiv = React.createRef();
        this.fruitFeatures = React.createRef();
        this.resizeElements = [this.tileContentsDiv];
        this.getNext = this.getNext.bind(this);
    }
    componentDidMount() {
        super.componentDidMount();
        this.setState({ d3: document.createElement('div') });
    }
    getNext() {
        this.nextButton.current.disabled = true;
        fetch('https://dannybierek.com:5000/fruit-classifier')
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({
                    imageURL: '/img/' + data.imageURL,
                    classification: data.classification,
                    features: data.features
                });

                this.nextButton.current.disabled = false;
            });
    }
    render() {
        return (<div className="Tile-back-contents" ref={this.tileContentsDiv}>
            <div id="fruit-classifier">
                {this.state.imageURL && <div ref={this.fruitImageDiv} id="fruit-image-div">
                    <div id="fruit-image">
                        <img src={process.env.PUBLIC_URL + this.state.imageURL} alt="Fruit" /><br />
                        Prediction: {this.state.classification}<br />
                    </div>
                    {this.state.features && <FruitFeaturesImages features={this.state.features} />}
                </div>}
                <button ref={this.nextButton} className="App-button" onClick={this.getNext} id="next-button">Load Image</button>
            </div>
        </div>
        );
    }
}
export default FruitClassifier;